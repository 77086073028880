@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: #000000;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Kolory */
.text-red-600 {
  color: #dc2626 !important;
}

.bg-black {
  background-color: #000000 !important;
}

.text-white {
  color: #ffffff !important;
}

.bg-brown {
  background-color: #9B6547 !important;
}

.border-white {
  border-color: #ffffff !important;
}

/* Layout */
.grid {
  display: grid !important;
}

.flex {
  display: flex !important;
}

.flex-col {
  flex-direction: column !important;
}

.flex-1 {
  flex: 1 1 0% !important;
}

.items-center {
  align-items: center !important;
}

.justify-center {
  justify-content: center !important;
}

.self-center {
  align-self: center !important;
}

/* Spacing */
.gap-2 {
  gap: 0.5rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-7 {
  margin-top: 1.75rem !important;
  margin-bottom: 1.75rem !important;
}

.mx-7 {
  margin-left: 1.75rem !important;
  margin-right: 1.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

/* Sizes */
.h-screen {
  height: 100vh !important;
}

.min-h-screen {
  min-height: 100vh !important;
}

.h-px {
  height: 1px !important;
}

.w-px {
  width: 1px !important;
}

.w-full {
  width: 100% !important;
}

/* Position */
.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.bottom-\[1\%\] {
  bottom: 1% !important;
}

.right-\[50\%\] {
  right: 50% !important;
}

/* Utilities */
.overflow-hidden {
  overflow: hidden !important;
}

.overflow-auto {
  overflow: auto !important;
}

.border {
  border-width: 1px !important;
  border-style: solid !important;
}

.opacity-80 {
  opacity: 0.8 !important;
}

/* Text sizes */
.text-4xl {
  font-size: 2.25rem !important;
}

.text-3xl {
  font-size: 1.875rem !important;
}

@media (min-width: 768px) {
  .md\:text-5xl {
    font-size: 3rem !important;
  }

  .md\:text-6xl {
    font-size: 3.75rem !important;
  }
}

/* Logo size */
.w-\[8\%\] {
  width: 8% !important;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 99vw;
  height: 100vh;
  z-index: -1;
  background-image: url('./assets/background-food.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.logo {
  position: relative;
  top: 50%;
  left: 50%;
  /* transform: translate(-50%, 23%); */
  transform: translate(-50%, -50%);
  width: 10%;
}

.box {
  border: 3px solid white;
  align-content: center;
  padding: 10px;
  font-size: 100%;
  font-weight: 200;
  overflow: hidden;
}

.default {
  font-size: 50px;
}